import React, { useState, useEffect } from 'react';

const Clock = () => {
	const [date, setDate] = useState(new Date());

	useEffect(() => {
		const timerID = setInterval(() => tick(), 100);

		return () => clearInterval(timerID);
	}, []);

	const tick = () => {
		setDate(new Date());
	};

	const formatTime = (time) => {
		return (time < 10) ? '0' + time : time;
	}

	const clockstyle = (time) => {
		if (time > 500)
			return { opacity: 0 };
		else
			return { opacity: 1 };
	}

	const jour = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
	const mois = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];

	const hours = formatTime(date.getHours());
	const minutes = formatTime(date.getMinutes());
	const style = clockstyle(date.getMilliseconds());

	const day = jour[date.getDay()];
	const date_num = date.getDate();
	const month = mois[date.getMonth()];

	return <div>
		<div className='back-datetime'>
			<span className="time">
				<span>{hours}</span>
				<span style={style}>:</span>
				<span>{minutes}</span>
			</span>
			<span className="date">
				{day} {date_num} {month}
			</span>
		</div>
	</div>;
};



export default Clock;
