import React, { memo } from 'react';
import wind from './assets/img/svg/wind.svg';
import wet from './assets/img/svg/wet.svg';
import moisture from './assets/img/svg/moisture.svg';

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const getFullDate = (timestamp) => {
	const days = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
	const date = new Date(timestamp * 1000);
	return `${days[date.getDay()]} ${date.getDate()}`;
};

const gstyle = (temp, dmax, dmin) => ({
	bottom: `${((temp - dmin) / (dmax - dmin)) * 100}px`,
});

const rstyle = (i, temp, temp2, dmax, dmin) => {
	const bottom = ((temp - dmin) / (dmax - dmin)) * 100;
	const bottom2 = ((temp2 - dmin) / (dmax - dmin)) * 100;
	const deg = (Math.atan2(bottom2 - bottom, 60) * -180) / Math.PI;
	return {
		transform: `rotate(${deg}deg)`,
		bottom: `${(bottom + bottom2) / 2}px`,
	};
};

const gtime = (timestamp) => `${new Date(timestamp * 1000).getHours()}:00`;

const getMax = (all) => Math.max(...all.slice(0, 15).filter((_, i) => i % 2 === 0).map(item => item.main.temp));

const getMin = (all) => Math.min(...all.slice(0, 15).filter((_, i) => i % 2 === 0).map(item => item.main.temp));

const greetings = () => {
	const hour = new Date().getHours();
	if (hour >= 5 && hour < 12) return "Bonjour";
	if (hour < 18) return "Bon après-midi";
	if (hour < 22) return "Bonne soirée";
	return "Bonne nuit";
};

const isRainSoon = (weather) => {
	const hourly = weather.list;
	for (let i = 0; i < hourly.length && i <= 12; i++) {
		if (hourly[i].rain) {
			return `${capitalizeFirstLetter(hourly[i].weather[0].description)} dans ${i} heures.`;
		}
	}
	return '';
};

const BlockTemp = memo(({ temp, i, max, min }) => {
	const className = min === temp ? 'temp_min' : max === temp ? 'temp_max' : 'canva_nonblock_temp';
	return (
		<div className={`canva_block_temp ${className}`} style={gstyle(temp, max, min)}>
			{className && <b>{Math.round(temp)}</b>}°<b>C</b>
		</div>
	);
});

const Home = ({ weatherData }) => {
	if (!weatherData || !weatherData.list) {
		return (
			<>
				<div className='bottom-shadow'></div>
				<div className="title">
					<h1>{greetings()}, </h1>
				</div>
			</>
		);
	}

	const weather = weatherData.list[1];

	return (
		<>
			<div className='bottom-shadow' />
			<div className='temperature'>
				<span className='description'>
					{capitalizeFirstLetter(weather.weather[0].description)}
				</span>
				<span>
					<span className='value'>{Math.round(weather.main.temp)}</span>
					<span className='unit'>°C</span>
				</span>
			</div>
			<div className='details'>
				<div>
					<br />
					<div className='is-inline-block ml-3'>
						<img src={wind} className='white-svg det_img' />
						{Math.round(weather.wind.speed)} Km/h <br />
					</div>
					<div className='is-inline-block ml-3'>
						<img src={wet} className='white-svg det_img' />
						{Math.round(weather.pop * 100)} % <br />
					</div>
					{weather.rain && weather.rain >= 1 && (
						<div className='is-inline-block ml-3'>
							<img src={moisture} className='white-svg det_img' />
							{Math.round(weather.rain)} mm <br />
						</div>
					)}
				</div>
			</div>
			<div className="title">
				<h1>{greetings()}, </h1>
				<p className='ml-3'>{isRainSoon(weatherData)}</p>
			</div>
		</>
	);
};

const Weather = ({ weatherData }) => {
	if (!weatherData || !weatherData.list) {
		return null;
	}

	return (
		<table className="Weather">
			<tbody>
				<tr>
					{weatherData.list.slice(0, 36).map((weather, i) => (
						<React.Fragment key={i}>
							{(i + 2) % 8 === 0 && (
								<td key={i}>
									<div className='weather_block'>
										<br />
										<span>{getFullDate(weather.dt)}</span>
										<br />
										<img src={`weather/${weather.weather[0].icon}.png`} className='type' />
										<br /><br />
										<span className='temp'>{Math.round(weather.main.temp.day)}°<b>C</b></span>
										<br /><br />
										<div>
											<span className='temp_min'><b>{Math.round(weather.main.temp.min)}</b>°<b>C</b></span>
											<span className='temp_space'></span>
											<span className='temp_max'><b>{Math.round(weather.main.temp.max)}</b>°<b>C</b></span>
										</div>
										<br />
										<span>{capitalizeFirstLetter(weather.weather[0].description)}</span>
									</div>
									<div className='weather_details'>
										<img src={wind} className='white-svg det_img' />
										{Math.round(weather.wind_speed)} Km/h <br />
										<img src={wet} className='white-svg det_img' />
										{Math.round(weather.pop * 100)} % <br />
										{weather.rain && weather.rain >= 1 ? (
											<>
												<img src={moisture} className='white-svg det_img' />
												{Math.round(weather.rain)} mm <br />
											</>
										) : (
											<div style={{ height: 25 }} />
										)}
									</div>
								</td>
							)}
						</React.Fragment>
					))}
				</tr>
			</tbody>
		</table>
	);
}

const Graph = ({ weatherData }) => {
	if (!weatherData || !weatherData.list) {
		return null;
	}

	const max = getMax(weatherData.list);
	const min = getMin(weatherData.list);

	return (
		<table className="Graph weather">
			<tbody>
				<tr>
					{weatherData.list.slice(0, 16).map((weather, i) => (
						<React.Fragment key={i}>
							<td>
								<div className='weather_block'>
									<br /><br />
									<BlockTemp temp={weather.main.temp} i={i} max={max} min={min} />
									<div className='canva_point_temp' style={gstyle(weather.main.temp, max, min)}></div>
									<div className='canva_line' style={rstyle(i, weather.main.temp, weatherData.list[i + 1].main.temp, max, min)}></div>
									{weatherData.list[i - 1] ? (
										weatherData.list[i - 1].weather[0].icon.replace("04", "03") === weatherData.list[i].weather[0].icon.replace("04", "03") ? (
											<div className="fake_img"><span></span></div>
										) : (
											<img src={`weather/${weather.weather[0].icon}.png`} />
										)
									) : (
										<img src={`weather/${weather.weather[0].icon}.png`} />
									)}
									<br />
									{(i + 2) % 2 === 0 ? (
										<>
											<span className="canva_lineup_time"></span>
											<div className="canva_block_time">{gtime(weather.dt)}</div>
										</>
									) : (
										<>
											<span className="canva_linenone_time"></span>
											<div className="canva_block_time"></div>
										</>
									)}
								</div>
							</td>
						</React.Fragment>
					))}
				</tr>
			</tbody>
		</table>
	);
};

export default Home;
export { Weather, Graph };
