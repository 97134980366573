import React from 'react';
import maintenance from './assets/img/maintenance.png';

const Trains = ({ trains, error }) => {
    return (
        <div className="Départ">
            {trains && trains.length ? (
                trains[0].departures.map((departure, i) => (
                    <Train key={i} train={departure} line={trains[0]} number={i} />
                ))
            ) : (
                <div className='maintenance'>
                    <img src={maintenance} alt="Maintenance" />
                    <br />
                    <b>Quelque chose s'est mal passé</b>
                    {error && (
                        <>
                            <br />
                            {error}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

const Train = ({ train, line }) => {
    if (train.informations.message === 'terminus')
        return null;

    return (<>
        <div className={'train'} style={{ backgroundColor: `#${line.color}22` }} >
            <div className='info'>
                <img src='class/N.png' className='img' />
                <span className={"diode"} style={{width: 60, textAlign: 'center'}}>
                    {train.informations.headsign}
                </span>
                
                <TimeBlock
                    time={train.stop_date_time.departure_date_time}
                    base={train.stop_date_time.base_departure_date_time}
                    state={getState(train)}
                    track={train.stop_date_time.platform}
                />
                <b className={`dest ${getState(train)}`}>
                    {train.informations.direction.name}
                </b>
            </div>
        </div>
        <div className={'bottomBar'} style={{ backgroundColor: `#${line.color}` }} /></>
    );
};

const TimeBlock = ({ time, state, base }) => {
    return (
        <div className={`is-flex is-align-items-end`}>
            {time !== '' && time.length > 1 && (
                <div
                    className='departure'
                >
                    <span className='dep'
                        style={{
                            color: getDeparturesColorByState(getTimeDifference(time) >= 0 ? state : 'theorical'),
                        }}
                    >
                        {getTime(base)}
                    </span>
                </div>
            )}
            {
                state == 'delayed' && <div className='state delayed'>
                    <b>
                        +{getLate({ stop_date_time: { departure_date_time: time, base_departure_date_time: base } })}min
                    </b>
                </div>
            }
            {
                state == 'canceled' && <div className='state canceled'>
                    <b> Supprimé </b>
                </div>
            }
        </div>
    );
};

function getTimeDifference(time) {
    const dttime = new Date(time);
    const dtnow = new Date();

    const diff = dttime - dtnow;

    return Math.floor(diff / 60000); // Convert milliseconds to minutes
}

function getState(train) {
    const state = train.stop_date_time.state;

    const res = [];
    if (getLate(train) > 0) {
        res.push('delayed');
    } else {
        res.push(state)
    }

    if (res.length == 0) {
        res.push('ontime');
    }
    return res;
}

function getLate(train) {
    const departure = train.stop_date_time.departure_date_time;
    const expectedDeparture = train.stop_date_time.base_departure_date_time;

    if (!departure || !expectedDeparture) {
        return 0;
    }

    const dttime = new Date(departure);
    const dtexpe = new Date(expectedDeparture);
    const diff = (dttime - dtexpe) / (1000 * 60); // Difference in minutes

    return Math.round(diff);
}

function getTime(time) {
    if (time === '') {
        return '';
    }

    let dttime = new Date(time);

    const dthour = dttime.getHours() < 10 ? `0${dttime.getHours()}` : dttime.getHours().toString();
    const dtminute = dttime.getMinutes() < 10 ? `0${dttime.getMinutes()}` : dttime.getMinutes().toString();

    return `${dthour}:${dtminute}`;
}

function getDeparturesColorByState(state) {
    if (state.length === 1 && state.includes('theorical')) {
        return '#a9a9a9';
    }

    return '#fcc900';
}

export default Trains;
