import React from 'react';
import { decode } from 'html-entities';

import error from './assets/img/error_small.png';
import valid from './assets/img/valid_small.png';
import work from './assets/img/work_small.png';
import warning from './assets/img/warning_small.png';
import futureWork from './assets/img/futur_work_small.png';
import interogation from './assets/img/interogation_small.png';
import information from './assets/img/information_small.png';

import TRAIN_N from './assets/img/idfm/lines/TRAIN_N.png';

function Trafic({trafic}) {
	if (!trafic || !trafic.length) return null;

	const severity = trafic[0].severity;
	const reports = [...trafic[0].reports.current_trafic, ...trafic[0].reports.current_work, ...trafic[0].reports.future_work];
	
	let color;
	let typeImg;
	let messsage = '';

	if (severity === 0) {
		color = 'slug0';
		typeImg = valid;
		messsage = 'Trafic fluide';
	} else if (severity === 5) {
		color = 'slug1';
		typeImg = error
		messsage = 'Trafic fortement perturbé';
	} else if (severity === 4) {
		color = 'slug2';
		typeImg = warning;
		messsage = 'Trafic perturbé';
	} else if (severity === 3) {
		color = 'slug2';
		typeImg = work;
		messsage = 'Travaux';
	} else if (severity === 2) {
		color = 'slug0';
		typeImg = futureWork;
		messsage = 'Travaux à venir';
	} else if (severity === 1) {
		color = 'slug0';
		typeImg = information;
		messsage = 'Information';
	} else {
		color = 'slug0';
		typeImg = interogation;
		messsage = 'Trafic fluide';
	}

	return <div className="App">
		<div className='trafic'>
		<span className={'trafic_block ' + color}>
			<img src={TRAIN_N} className='img' />
			<img src={typeImg} className='trafic_img' />
		</span>
		<b className='message'>{messsage}</b>
		<div className='Traficails'>
			{
				reports.map((report, i) => (
					<>
						<b>
							{report.message.title}
						</b>
						<br />
						<p>{report.message.text}</p>
					</>
				))
			}
		</div>
	</div>
	</div>
}

function SNCF_trafic({ line, trafic, name, img }) {
	let color;
	let typeImg;
	let messsage = '';

	if (typeof trafics === "undefined") {
		return null;
	}

	if (trafic === false) {
		return <div className='trafic'>
			<span className="trafic_block slug0">
				<img src={img} className='img' />
				<span className='trafic_img' ></span>
			</span>
		</div>;
	}

	
}

function SNCF_det({ trafic }) {
	let reports = trafic.reports;

	return <>
		{reports.current_trafic.map((report, i) => (
			<>
				<SNCF_trim_title
					txt={report.message.title}
				/>
				<br />
				<SNCF_trim_detail
					txt={report.message.text}
				/>
			</>
		))}
		{reports.current_work.map((report, i) => (
			<>
				<SNCF_trim_title
					txt={report.message.title}
				/>
				<br />
				<SNCF_trim_detail
					txt={report.message.text}
				/>
			</>
		))}
		{reports.future_work.map((report, i) => (
			<>
				<SNCF_trim_title
					txt={report.message.title}
				/>
				<br />
				<SNCF_trim_detail
					txt={report.message.text}
				/>
			</>
		))}
	</>
}

function SNCF_trim_title({ txt }) {
	let text = txt;
	
	text = text.replaceAll("Ligne N :", "");

	return <b>
		{capitalizeFirstLetter(text)}
	</b>
}
function SNCF_trim_detail({ txt }) {
	let text = txt;
	text = text.replaceAll("<br>", "\n");
	text = text.replace(/(<([^>]+)>)/gi, "");

	return <span className='line'>
		{decode(text)}
	</span>
}
function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export default Trafic;
